<div class="min-h-screen flex flex-col header-fixed-sm">
  <!-- Header -->
  <div header class="header header-dark"></div>
  <!-- Body -->
  <main class="body mb-auto">
    <router-outlet></router-outlet>
  </main>
  <!-- Footer -->

  <footer class="z-50 footer hidden md:block">
    <div class="flex flex-col justify-center py-10">
      <div class="text-center">
        <img src="images/logo.png" alt="" />
      </div>
      <div class="mt-2 text-slate-400 text-center">
        <a [routerLink]="['/blog/cdv']">
          <i class="bi bi-file-text"></i>
          {{ "navbar.cdv" | translate }}
        </a>
      </div>
      <div class="mt-2 text-slate-400 text-center">
        <a [routerLink]="['/blog/allergy-ingredients-list']">
          <i class="bi bi-file-earmark-pdf"></i>
          {{ "home.landing.anchor_allergy_statement" | translate }}
        </a>
      </div>
      <div class="mt-2 text-slate-400 text-center">
        <div>
          &copy;
          <span
            [translate]="'app.copyrights'"
            [translateParams]="{ name: 'Sushi Moon' }"
          ></span>
        </div>
        <div>
          &copy;
          <span
            [translate]="'app.powered_by'"
            [translateParams]="{ name: 'WaiMai.be' }"
          ></span>
        </div>
        <div>&hearts; App Vers. : {{ appVersion }}</div>
      </div>
    </div>
  </footer>
</div>
