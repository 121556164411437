import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { of, throwError } from "rxjs";
import { catchError, map, switchMap, take } from "rxjs/operators";
import { get } from "lodash";
import { NzMessageService } from "ng-zorro-antd/message";
import { Observable, zip } from "rxjs";
import { environment } from "@environments/environment";
import { IHttpResponseError } from "../interfaces";
import { AuthCoreService } from "@app/api/auth";
import { StateHelperService } from "../../state";
import { CartApiService } from "@app/api/cart";

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, private router: Router) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.addAuthHeader(req).pipe(
      switchMap((tokenize) => {
        return next
          .handle(tokenize)
          .pipe(catchError((error) => this.handleResponseError(error)));
      })
    );
  }

  addAuthHeader(req: HttpRequest<any>): Observable<HttpRequest<any>> {
    const authService = this.injector.get(AuthCoreService);
    const stateService = this.injector.get(StateHelperService);
    const cartService = this.injector.get(CartApiService);
    const merchantId = environment.merchantId;
    if (req.url.startsWith("https://www.ebonline.be")) {
      return of(req);
    }

    return zip(
      authService.accessToken$,
      cartService.cartToken$,
      stateService.livemode$
    ).pipe(
      take(1),
      map((res) => {
        const [accessToken, cartToken, livemode] = res;

        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.set("mch", `${merchantId}`);

        if (accessToken) {
          headers = headers.set("Authorization", `Bearer ${accessToken}`);
        }

        if (cartToken) {
          headers = headers.set("cart", `${cartToken}`);
        }

        headers = headers.set("livemode", livemode ? "true" : "false");

        const tokenized = req.clone({
          withCredentials: environment.withCredentials,
          headers,
        });
        return tokenized;
      })
    );
  }

  handleResponseError(err: HttpErrorResponse): Observable<any> {
    let message;
    let problems;
    let error: IHttpResponseError;

    const msgService = this.injector.get(NzMessageService);
    const { status, error: response } = err;
    if (response && "error" in response) {
      message = get(response, ["error", "message"], "Unknown error");
      problems = get(response, ["error", "problems"], []);
      error = response.error;
    } else {
      message = get(response, ["message"], "Unknown error");
      problems = get(response, ["problems"], []);
      error = response;
    }

    if (status === 0) {
      msgService.error(`Service unavailable, please try again later`);
    }

    if (status === 400) {
      const title = `Http Request Error`;
      msgService.error(`${message}`);
    }

    if (status === 401) {
      const auth = this.injector.get(AuthCoreService);
      const msg = msgService.loading(`跳转中....`);

      auth
        .destroy()
        .pipe(take(1))
        .subscribe((res) => {
          if (res) {
            msgService.remove(msg.messageId);
            this.router.navigate(["/session/sign-in"]);
          }
        });
    }

    if (status === 403) {
      msgService.warning(message);
      const stateService = this.injector.get(StateHelperService);
      stateService.livemode = true;
      // this.toLoginPage();
    }

    if (status === 404) {
      msgService.error(`404: Api Path not found`);
    }

    if (status === 500) {
      msgService.error(`${message}`);
    }

    if (status === 503) {
      msgService.warning(`${message}`);
    }

    if (status > 500) {
      msgService.error(`Server Error, ${message}`);
    }

    if (problems.length > 0) {
      msgService.error(`${problems}`);
    }

    return throwError({ error });
  }
}
