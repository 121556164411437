import { CommonModule, DOCUMENT, isPlatformBrowser } from "@angular/common";
import { Component, inject, PLATFORM_ID } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { Meta } from "@angular/platform-browser";
import { RouterModule, RouterOutlet } from "@angular/router";
import { environment } from "@environments/environment";
import { TranslateModule } from "@ngx-translate/core";
import { DateFnsConfigurationService } from "ngx-date-fns";
import { NotificationApiService } from "./api/notification";
import { ShopApiService } from "./api/shop";
import { ApiStatusService } from "./api/status";
import { CanonicalService } from "./helpers/seo/canonical";
import { TranslateHelperService } from "./helpers/translate-helper";
import { LayoutsModule } from "./layouts";
import { metadata } from "./app.data";
@Component({
  selector: "app-root",
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterModule,
    FormsModule,
    LayoutsModule,
    TranslateModule,
  ],
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  appVersion: string;
  private platformId = inject(PLATFORM_ID);
  private doc = inject(DOCUMENT);
  private statusService: any;
  private shopService: any;
  private noticeService: any;
  private title = "";
  private translate: any;
  private canonicalService: any;
  private dfnsConfig: any;
  private meta = inject(Meta);

  constructor() {
    this.appVersion = environment.appVersion;
    if (isPlatformBrowser(this.platformId)) {
      this.statusService = inject(ApiStatusService);
      this.noticeService = inject(NotificationApiService);
      this.shopService = inject(ShopApiService);
      this.translate = inject(TranslateHelperService);
      this.canonicalService = inject(CanonicalService);
      this.dfnsConfig = inject(DateFnsConfigurationService);
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.translate.setDateFnsLocale(this.dfnsConfig);
      this.setMeta();
      this.canonicalService.setCanonicalURL();
      if (!this.statusService.gmapApiLoaded()) {
        this.setGoogleMap();
      }
    }
    // this.initNotification();
    // this.initShop();
  }

  private setMeta(): void {
    this.meta.addTags(metadata);
  }

  private initNotification(): void {
    // this.noticeService.initList().pipe(take(1)).subscribe();
  }

  private initShop(): void {
    // this.shopService.init().pipe(take(1)).subscribe();
  }

  private setGoogleMap() {
    const apiKey = environment.googleMapApiKey;
    const s = this.doc.createElement("script");
    s.type = "text/javascript";
    s.innerHTML = `
  (g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=\`https://maps.\${c}\apis.com/maps/api/js?\`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({
      v: "weekly",
      key: \`${apiKey}\`
    })
    `;

    const body = this.doc.getElementsByTagName("body")[0];
    body.appendChild(s);
    this.statusService.gmapApiLoaded.set(true);
  }
}
