export const environment = {
  title: "Sushi Moon | Traiteur Japonais Bruxelles",
  businessName: "Sushi Moon",
  appVersion: require("../../package.json").version + "--dev",
  // coreEndPoint: "http://192.168.0.173:3900",
  // authEndPoint: "http://192.168.0.173:3900",
  coreEndPoint: "https://mch.service.waimai.io",
  authEndPoint: "https://mch.service.waimai.io",
  googleMapApiKey: "AIzaSyDsPTA6r0yL_EYCK5dCMFrdU76wOM2k9Lc",
  // merchantId: "mch_E4JPxZIWoc",
  merchantId: "mch_mxnfVA1k37",
  gaId: "UA-84463413-1",
  production: false,
  withCredentials: false,
};
