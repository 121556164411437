import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  Optional,
  PLATFORM_ID,
  provideZoneChangeDetection,
  TransferState,
} from "@angular/core";
import { provideRouter, Router } from "@angular/router";

import { routes } from "./app.routes";
import {
  provideClientHydration,
  withEventReplay,
  withI18nSupport,
} from "@angular/platform-browser";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { isPlatformBrowser } from "@angular/common";
import { CRAWLER_AGENTS, VIEWER } from "./common/constants/crawler";
import { Request } from "express";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import {
  exportTranslateStaticLoader,
  InitializerService,
  initProviderFactory,
} from "./api/initializer";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NzDrawerModule } from "ng-zorro-antd/drawer";
import { NzMessageModule } from "ng-zorro-antd/message";
import { environment } from "../environments/environment.development";
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from "ngx-google-analytics";
import { QueryListCountPipe } from "./components/standalone/query-list-count";
import { NZ_I18N, fr_FR, NZ_DATE_LOCALE } from "ng-zorro-antd/i18n";
import { HttpRequestInterceptor } from "./helpers/http/interceptors";
import { fr as french } from "date-fns/locale";
import { REQUEST } from "@app/express.tokens";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzImageModule } from "ng-zorro-antd/image";
import * as Sentry from "@sentry/angular";

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideClientHydration(withI18nSupport(), withEventReplay()),
    provideHttpClient(withFetch()),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom([
      TranslateModule.forRoot({
        defaultLanguage: "fr",
        loader: {
          provide: TranslateLoader,
          useFactory: exportTranslateStaticLoader,
          deps: [HttpClient, TransferState],
        },
        isolate: false,
        useDefaultLang: true,
      }),
    ]),
    importProvidersFrom(NzMessageModule),
    importProvidersFrom(NzDrawerModule),
    importProvidersFrom(NzModalModule),
    importProvidersFrom(NzImageModule),
    importProvidersFrom(BrowserAnimationsModule),
    importProvidersFrom(NgxGoogleAnalyticsModule.forRoot(environment.gaId)),
    importProvidersFrom(NgxGoogleAnalyticsRouterModule),

    QueryListCountPipe,
    { provide: NZ_I18N, useValue: fr_FR },

    {
      provide: NZ_DATE_LOCALE,
      useValue: french,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initProviderFactory,
      deps: [InitializerService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    {
      provide: VIEWER,
      useFactory: viewerFactory,
      deps: [PLATFORM_ID, [new Optional(), REQUEST]],
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
};

export function viewerFactory(platformId: any, req: Request): string {
  if (isPlatformBrowser(platformId)) {
    return "user";
  }

  if (req?.get) {
    const userAgent = (req.get("user-agent") || "").toLowerCase();

    const isCrawler = CRAWLER_AGENTS.some(
      (crawlerAgent) => userAgent.indexOf(crawlerAgent) !== -1
    );
    return isCrawler ? "bot" : "user";
  }

  return "user";
}
