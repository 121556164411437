import { MetaDefinition } from "@angular/platform-browser";

export const metadata: MetaDefinition[] = [
  {
    name: "keywords",
    content:
      "Sushi Moon Belgique,  Bruxelles, Uccle, livraison, repas, gratuit, domicile, restaurant,Take Away, Plat à Emporter, Traiteur Chinois, Brussels, Bruxelles, Evere, Uccle, Etterbeek, Restaurant Chinois,",
  },
  {
    name: "description",
    content: `Sushi Moon,Traiteur Chinois à Bruxelles, Take Away, Plat à Emporter, Livraison, Delivery, LunchBox  Livraison gratuite des plats à domicile sur région de Bruxelles Brussels Brussel, Sushi Moon Belgique Restaurant`,
  },
];
