import { bootstrapApplication } from "@angular/platform-browser";
import { appConfig } from "./app/app.config";
import { AppComponent } from "./app/app.component";
import * as Sentry from "@sentry/angular";

Sentry.init({
  dsn: "https://69590d39bfc78a98166762dc7dee31ad@o1204728.ingest.us.sentry.io/4508976359866368",
});

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err)
);
